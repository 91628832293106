import React, {useState} from 'react';
import 'react-tabs/style/react-tabs.css';
import Header from '../layout/Header';
import Detailbox from './Detail_box';
import { useTranslation } from 'react-i18next';

export const Single_style2 = () => {
    
        const { t } = useTranslation();
        const [projets, setProjects] = useState([
            {
                name: "Smart press", 
                company: "Della Toffola",  
                category: "Wine Manufacturing Equipment",
                location: "Tunis",
                link: "www.dellatoffola.fr",
                date: "6 June 19",
                image: "smart_presses.jpg",
                card: "smart_presses.jpg",
                video: "",
                text1: t("prj1Text1"),
                text2: t("prj1Text2")  
            },
            {
                name: "The smart EYE", 
                company: "Della Toffola",  
                category: "Wine Manufacturing Equipment",
                location: "Tunis",
                link: "www.dellatoffola.fr",
                date: "20 April 20",
                image: "oeil_intelligent.jpg",
                card: "oeil_intelligent.jpg",
                video: "",
                text1: t("prj2Text1"),
                text2: t("prj2Text2")  
            },
            {
                name: "Methania", 
                company: "Jellagaz project",  
                category: "Micro Methanization Equipment",
                location: "Tunis",
                link: "www.linkedin.com/company/methania",
                date: "1 June 20",
                image: "metania_jella_gas.jpg",
                card: "metania_jella_gas.jpg",
                video: "",
                text1: t("prj3Text1"),
                text2: t("prj3Text2")  
            },
            {
                name: "Dynamic Digital Signage Network", 
                company: "Digit-S ",  
                category: "Imagery Detection IA",
                location: "Tunis",
                link: "digit-s.tn",
                date: "1 September 19",
                image: "",
                card: "digits.jpg",
                video: "azure2 demo 10secs.mp4",
                text1: t("prj4Text1"),
                text2: t("prj4Text2")  
            }
        ]);
        const [seletedPrjIndex, setSeletedPrjIndex] = useState(0);

        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row ">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">{t('Realisations')}</h1>
                            </div>{/* /.page-title-captions */}  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                <section className="ttm-row project-single-section clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 col-xl-8">
                            <h4>{projets[seletedPrjIndex]?.name}</h4>
                            <p>{projets[seletedPrjIndex]?.text1}</p>
                            <p>{projets[seletedPrjIndex]?.text2}</p>
                            <div className="row">
                                {projets[seletedPrjIndex]?.image && <div className="col-md-12">
                                    {/* ttm_single_image-wrapper */}
                                    <div className="ttm_single_image-wrapper mt-35">
                                        <img className="img-fluid" src={`/images/projects/${projets[seletedPrjIndex]?.image}`} title={projets[seletedPrjIndex]?.name} alt={projets[seletedPrjIndex]?.name} />
                                    </div>{/* ttm_single_image-wrapper end */}
                                </div>}
                                {projets[seletedPrjIndex]?.video && <div className="col-md-12">
                                    {/* ttm_single_image-wrapper */}
                                    <div className="ttm_single_image-wrapper mt-35">
                                        <video autoplay="true" loop="true" className="img-fluid"  title={projets[seletedPrjIndex]?.name} alt={projets[seletedPrjIndex]?.name} >
                                            <source src={`/images/projects/${projets[seletedPrjIndex]?.video}`} type="video/mp4"></source>    
                                        </video>
                                    </div>{/* ttm_single_image-wrapper end */}
                                </div>}
                                {/* separator */}
                                <div className="col-12">
                                    <div className="separator">
                                        <div className="sep-line mt-4 mb-35" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="ttm-nextprev-bottom-nav mb-4">
                                        <nav className="navigation post-navigation">
                                        <div className="nav-links">
                                            <div className="nav-links">
                                            <a onClick={()=>{setSeletedPrjIndex(s => (s-1+4)%4)}} className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-icon-btn-left"><i className="fa fa-angle-double-left" />{t("Previous")}</a> 
                                            <a onClick={()=>{setSeletedPrjIndex(s => (s+1)%4)}} className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-icon-btn-right float-md-right">{t("Next")}<i className="fa fa-angle-double-right" /></a> 
                                            </div>
                                        </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-xl-4">
                                <Detailbox project={projets[seletedPrjIndex]}/>
                            </div>
                        </div>
                        {/* row end*/}
                        {/* row */}
                        <div className="row ttm-pf-single-related-wrapper ">
                            <div className="col-12">
                                <h4 className="mb-30">Autres Projets</h4>
                            </div>
                            {projets?.map((projet, projetIndex) => (projetIndex!=seletedPrjIndex) && <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                    <img className="img-fluid" src={`/images/projects/${projet?.card}`} title={projet?.name} alt={projet?.name} />
                                    </div>{/* featured-thumbnail end*/}
                                    <div className="featured-content">
                                    <div className="category">
                                        <p>{projet.category}</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a onClick={()=> setSeletedPrjIndex(projetIndex)}>{projet.name}</a></h5>
                                    </div>
                                    </div>
                                </div>{/* featured-imagebox */}
                            </div>)}                            
                        </div>{/* row end*/}
                    </div>
                </section>
            </div>
        )
    
}


export default Single_style2;