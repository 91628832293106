import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';

export const Detailbox = ({project}) => {
    const { t } = useTranslation();
        return (
            <div className="ttm-pf-single-detail-box mb-35">
                <div className="ttm-pf-single-title">
                <h5>Informations</h5>
                </div>
                <ul className="ttm-pf-detailbox-list">
                <li><i className="fa fa-user" /><span> {t("Client")} : </span>{project?.name}</li>
                <li><i className="far fa-bookmark" /><span> {t("Category")} :</span>{project?.category}</li>
                <li><i className="fa fa-calendar" /><span> {t("Date")} :</span>{project?.date}</li>
                <li><i className="ti ti-world" /><span> {t("Web site")} : <a target="_blank" href={`https://${project?.link}`} rel="noreferrer">{project?.link} </a>  </span> </li>
                <li><i className="fa fa-map-marker" /><span>{t("Location")} : </span>{project?.location}</li>
                </ul>
            </div>
            )
    
}


export default Detailbox;