import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from '../layout/Header';
import { useTranslation } from 'react-i18next';

export const Data_structuring = () => {
    const { t } = useTranslation();
    
        return (
            <div className="site-main">
                <Header/>
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h1 className="title">{t("Header2")}</h1>
                            </div>{/* /.page-title-captions */}
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-11 content-area order-lg-2">
                            {/* ttm-service-single-content-are */}
                            {/* ttm-service-single-content-are */}
                            <div className="ttm-service-single-content-area">
                                <div className="ttm-service-description">
                                    <div className="row">
                                        <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src="/images/nosservices_audit.jpg" alt="single-img-eighteen" />
                                        </div>
                                        </div>
                                        <div className="col-md-7">
                                        <h3 className="res-767-mt-20">{t("Audit")}</h3>
                                        <p style={{textAlign: "justify"}}>{t("AuditText1")}
                                        </p>
                                        <p className="mb-0" style={{textAlign: "justify"}}>{t("AuditText2")}</p>
                                        </div>
                                    </div>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                                    </div>
                                    {/* separator */}
                                    <div className="row mb-35">
                                        <div className="col-md-7">
                                        <h4>{t("DataPrep")}</h4>
                                        <p style={{textAlign: "justify"}}>{t("DataPrepText1")}</p>
                                        <p style={{textAlign: "justify"}}>{t("DataPrepText2")}</p>
                                        </div>
                                        <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper res-767-mt-20">
                                            <img className="img-fluid" src="/images/nosservices_preparation_donnees.jpg" alt="single-img-nineteen" />
                                        </div>
                                        </div>
                                    </div>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                                    </div>
                                    {/* separator */}
                                    <div className="row mb-35">
                                        <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper res-767-mt-20">
                                            <img className="img-fluid" src="/images/nosservices_analyse.jpg" alt="single-img-nineteen" />
                                        </div>
                                        </div>
                                        <div className="col-md-7">
                                        <h4>{t("Analyse")}</h4>
                                        <p style={{textAlign: "justify"}}>{t("AnalyseText1")}</p>
                                        </div>
                                    </div>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                                    </div>
                                    {/* separator */}
                                    <div className="row mb-35">
                                        <div className="col-md-7">
                                        <h4>{t("ModelingPred")}</h4>
                                        <p style={{textAlign: "justify"}}>{t("ModelingPredText1")}</p>
                                        </div>
                                        <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper res-767-mt-20">
                                            <img className="img-fluid" src="/images/nosservices_modelisation.jpg" alt="single-img-nineteen" />
                                        </div>
                                        </div>
                                    </div>
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                                    </div>
                                    {/* separator */}
                                    <div className="row mb-35">
                                        <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper res-767-mt-20">
                                            <img className="img-fluid" src="/images/nosservices_optimisation.jpg" alt="single-img-nineteen" />
                                        </div>
                                        </div>
                                        <div className="col-md-7">
                                        <h4>{t("Optimization")}</h4>
                                        <p style={{textAlign: "justify"}}>{t("OptimizationText1")}</p>
                                        </div>
                                    </div>{/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                                    </div>
                                    {/* separator */}
                                    <div className="row mb-35">
                                        <div className="col-md-7">
                                        <h4>{t("RD")}</h4>
                                        <p style={{textAlign: "justify"}}>{t("RDText1")}</p>
                                        </div>
                                        <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper res-767-mt-20">
                                            <img className="img-fluid" src="/images/extra1.jpg" alt="single-img-nineteen" />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ttm-service-single-content-are end */}
                        </div>
                        <div className="col-2 widget-area">
                        </div>
                    </div>{/* row end */}
                    </div>
                </div>
            </div>
            )
   
}


export default Data_structuring;